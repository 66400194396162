import { React, useState, useEffect, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { useQRCode } from "next-qrcode";

import { useStateValue } from "state";
import { fireblocksAssets } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";
import * as Fireblocks from "assets/javascript/fireblocks";

function ReceiveTokenModal() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({ selectedAddress: 0, currency: { chains: [] }, addresses: { "XPR Network": "" }, addressesFireblocks: {}, selectedChain: "XPR Network" });
    const [data, setData] = useState({});
    const [state, dispatch] = useStateValue();
    const { Image } = useQRCode();

    useEffect(() => {
        if (state.inputModal?.action != "deposit") return;

        setInputs(values => (
            { ...values, currency: state.inputModal?.data?.currency, addresses: { "XPR Network": state.session.auth.actor, ...state.inputModal.data.addresses }, addressesFireblocks: { "XPR Network": state.session.auth.actor }, selectedChain: Object.keys(state.inputModal?.data?.currency?.chains)?.[0] }
        ));

        return handleDataFetch(state.inputModal?.data?.currency);
    }, [state.inputModal]);

    useEffect(() => {
        if (!state.closeInputModal) return;
    }, [state.closeInputModal]);

    useEffect(() => {
        if (state.connected == null) return;

        if (!state.connected) {
            $("div.input-modal, section").removeClass("active");
        };
    }, [state.connected]);

    function handleDataFetch(currency) {
        var showSuccess = (res) => {
            var addressesFireblocks = {};

            res.forEach(asset => {
                addressesFireblocks[fireblocksAssets[asset.id]?.chain] = asset.address;
            });

            setInputs(values => (
                { ...values, addressesFireblocks: { "XPR Network": state.session.auth.actor, ...addressesFireblocks } }
            ));
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error with your request, please try again later");
        };

        // check bridge.strx smart contract tables for deposit addresses (when implemented)
        Fireblocks.fetchWalletAddresses(state.session.auth.actor, currency?.name).then(res => {
            showSuccess(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err.responseText || err };

            showError(message);
        });
    };

    function handleTab(event, chain) {
        setInputs(values => (
            { ...values, selectedChain: chain }
        ));
    };

    function generateAuth(coin, wallet, fireblocks) {
        if (!coin) return console.log("missing_params");

        if ($(".qr-wrapper div.empty").hasClass("disabled")) return;

        $(".qr-wrapper div.empty").addClass("disabled").html("<i class='fad fa-spinner-third'></i>");

        var showSuccess = (res, fireblocks) => {
            dispatch({
                type: "fetchWalletBalances",
                value: true
            });

            if (fireblocks) {
                setInputs(values => (
                    { ...values, addressesFireblocks: { ...inputs.addressesFireblocks, [res.chain]: res.address } }
                ));
            } else {
                setInputs(values => (
                    { ...values, addresses: { ...inputs.addresses, [res.chain]: res.address } }
                ));
            };
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error with your request, please try again later");

            $(".qr-wrapper div.empty").removeClass("disabled").html("<i class='fal fa-wallet no-spin'></i><span>Create Address</span>");
        };

        if (fireblocks) {
            Fireblocks.generateDepositAddress(state.session.auth.actor, coin, wallet).then(res => {
                showSuccess(res, true);
            }).catch(err => {
                var { message } = err.responseJSON || err.responseText || { message: err };
    
                showError(message);
            });
        } else {
            Proton.generateAuth(state).then(res => {
                if (!res.transaction || !res.signatures || !res.signer) return;
    
                Account.generateDepositAddress(coin, wallet, res.signer, res.signatures, res.transaction).then(res2 => {
                    showSuccess(res2);
                }).catch(err2 => {
                    console.log(err2);
    
                    var { error } = err2.responseJSON;
    
                    showError(error);
                });
            }).catch(err => {
                var { message } = err.responseJSON || { message: err };
    
                showError(message);
            });
        };
    };

    return (
        <section className="deposit-address">
            <div className="content">
                <div className="form-title"><img src={inputs.currency?.logo} alt={inputs.currency?.name}></img> Receive {inputs.currency?.name}</div>

                <div className="deposit-wrapper">
                    <div className="tab-wrapper">
                        {
                            Object.keys(inputs.currency?.chains).map((chain, index) => (
                                <button key={index} className={"tab " + (inputs.selectedChain == chain ? "active" : "")} onClick={(e) => handleTab(e, chain)}>{chain}</button>
                            ))
                        }
                    </div>

                    <div className="qr-wrapper">
                        {
                            (inputs.currency?.fireblocks && inputs.addressesFireblocks?.[inputs.selectedChain] || !inputs.currency?.fireblocks && inputs.addresses?.[inputs.selectedChain]) && (
                                <Image text={(inputs.currency?.fireblocks) ? inputs.addressesFireblocks?.[inputs.selectedChain]?.split("?")[0] || "0x" : inputs.addresses?.[inputs.selectedChain]?.split("?")[0] || "0x"}></Image>
                            )
                        }

                        {
                            (inputs.currency?.fireblocks && !inputs.addressesFireblocks?.[inputs.selectedChain] || !inputs.currency?.fireblocks && !inputs.addresses?.[inputs.selectedChain]) && (
                                <div className="empty click" onClick={() => generateAuth(inputs.currency?.name, inputs.currency?.chains?.[inputs.selectedChain], inputs.currency?.fireblocks)}>
                                    <i className="fal fa-wallet no-spin"></i>
                                    <span>Create Address</span>
                                </div>
                            )
                        }

                        <div className="tag">
                            <span>Address</span>
                            <div>
                                {
                                    (inputs.currency?.fireblocks && inputs.addressesFireblocks?.[inputs.selectedChain] || !inputs.currency?.fireblocks && inputs.addresses?.[inputs.selectedChain]) && (
                                        (inputs.currency?.fireblocks) ? inputs.addressesFireblocks?.[inputs.selectedChain]?.split("?")[0] : inputs.addresses?.[inputs.selectedChain]?.split("?")[0]
                                    )
                                }

                                {
                                    (inputs.currency?.fireblocks && !inputs.addressesFireblocks?.[inputs.selectedChain] || !inputs.currency?.fireblocks && !inputs.addresses?.[inputs.selectedChain]) && (
                                        ". . ."
                                    )
                                }
                            </div>
                        </div>

                        {
                            (inputs.addresses?.[inputs.selectedChain]?.includes("memo")) && (
                                <div className="tag">
                                    <span>Memo</span>
                                    <div>{inputs.addresses?.[inputs.selectedChain]?.split("=")[1] || ""}</div>
                                </div>
                            )
                        }

                        {
                            (inputs.selectedChain == "XPR Network") && (
                                <p className="notice">Send { inputs.currency?.ticker } to the above address on the { inputs.selectedChain } network to receive { inputs.currency?.ticker } in your XPR Network account</p>
                            )
                        }

                        {
                            (inputs.selectedChain != "XPR Network") && (
                                <p className="notice">Send { inputs.currency?.name } to the above address on the { inputs.selectedChain } network to receive wrapped { inputs.currency?.ticker } in your XPR Network account</p>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ReceiveTokenModal;