import { React } from "react";
import { Helmet } from "react-helmet";
import { Routes, Route } from "react-router-dom";

import ServerError from "pages/error/500";
import NotFound from "pages/error/404";
import Maintenance from "pages/error/maintenance";
import Disabled from "pages/error/disabled";

import AdminPanel from "pages/admin-panel";

import Home from "pages/index";
import ListingPage from "pages/listing";
import ProfilePage from "pages/profile";
import Search from "pages/search";
import Category from "pages/category";
import Cart from "pages/cart";
import Checkout from "pages/checkout";
import Notifications from "pages/notifications";
import CreateListing from "pages/create-listing";
import EditListing from "pages/edit-listing";
import OrderConfirmation from "pages/order-confirmation";
import ShippingZones from "pages/shipping-zones";
import Messages from "pages/messages";
import BecomeSeller from "pages/become-seller";

import Dashboard from "pages/account/dashboard";
import Settings from "pages/account/settings";
import Wallet from "pages/account/wallet";
import Addresses from "pages/account/addresses";
import Orders from "pages/account/orders";
import Listings from "pages/account/listings";
import Sales from "pages/account/sales";
import Storefront from "pages/account/storefront";
import Arbitration from "pages/account/arbitration";
import Governance from "pages/account/governance";
import Staking from "pages/account/staking";
import Vouchers from "pages/account/vouchers";
import Premium from "pages/account/premium";
import Ads from "pages/account/ads";

import UserAgreement from "pages/user-agreement";
import PrivacyNotice from "pages/privacy-notice";

function Main() {
    return (
        <main>
            {
                /*
                <Helmet>
                    <title>Storex | E-commerce marketplace powered by blockchain technology</title>
                </Helmet>
                */
            }

            <Routes>
                <Route exact path="/" element={<Home />} />

                <Route exact path="/admin-panel" element={<AdminPanel />} />

                <Route exact path="/cart" element={<Cart />} />
                <Route exact path="/checkout" element={<Checkout />} />
                <Route exact path="/notifications" element={<Notifications />} />
                <Route exact path="/create-listing" element={<CreateListing />} />
                <Route exact path="/edit-listing" element={<EditListing />} />
                <Route exact path="/order-confirmation" element={<OrderConfirmation />} />
                <Route exact path="/shipping-zones" element={<ShippingZones />} />
                <Route exact path="/become-seller" element={<BecomeSeller />} />

                <Route path="/listing/:listingId" element={<ListingPage />} />

                <Route path="/search/:searchTerm" element={<Search />} />

                <Route path="/category/:categoryId/:subcategoryId?" element={<Category/>}/>

                <Route path="/profile/:userId" element={<ProfilePage />} />

                <Route exact path="/account" element={<Dashboard />} />
                <Route exact path="/account/settings" element={<Settings />} />
                <Route exact path="/account/wallet" element={<Wallet />} />
                <Route exact path="/account/addresses" element={<Addresses />} />
                <Route exact path="/account/orders" element={<Orders />} />
                <Route exact path="/account/listings" element={<Listings />} />
                <Route exact path="/account/sales" element={<Sales />} />

                <Route exact path="/messages/:userId?" element={<Messages/>}/>

                <Route exact path="/account/arbitration" element={<Arbitration/>}/>
                <Route exact path="/account/governance" element={<Disabled/>}/>
                <Route exact path="/account/staking" element={<Staking/>}/>
                <Route exact path="/account/vouchers" element={<Vouchers/>}/>
                <Route exact path="/account/premium" element={<Disabled/>}/>
                <Route exact path="/account/ads" element={<Disabled/>}/>

                <Route exact path="/user-agreement" element={<UserAgreement />} />
                <Route exact path="/privacy-notice" element={<PrivacyNotice />} />

                <Route path="/error" element={<ServerError />} />
                <Route path="/not-found" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </main>
    );
};

export default Main;
