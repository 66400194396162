import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";

import { useStateValue } from "state";
import { defaultAccount, currencyDictionary, languageDictionary, dummyCategories } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Cart from "assets/javascript/cart";
import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

import CartModal from "components/header/cart";
import WishlistModal from "components/header/wishlist";

function MobileModal({ web3auth }) {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({ category: "", search: "", wishlist: [], cart: [] });
    const [menuStates, setMenuState] = useState({ menu: false, cart: false, wishlist: false, account: false, search: false });
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) return dispatch({
            type: "setAccount",
            value: {
                account: false
            }
        });

        if (state.authenticated) {
            if (state.promptLogin) {
                navigate(state.promptLogin);

                dispatch({
                    type: "promptLogin",
                    value: null
                });
            };

            handleAccountDataFetch();
        };
    }, [state.authenticated]);

    useEffect(() => {
        if (state.closeMobileMenu) {
            setMenuState(values => (
                { ...values, menu: false }
            ));

            dispatch({
                type: "closeMobileMenu",
                value: null
            });
        };
    }, [state.closeMobileMenu]);

    useEffect(() => {
        if (menuStates.menu) {
            $(".cart-wrapper.active > .cart-overlay")[0].scrollTo({ top: 0, left: 0, behavior: "smooth" });
        };
    }, [menuStates.menu]);

    function handleMenu(event) {
        const target = event.target;

        var results = $(".cart-wrapper").find(target).length;

        if (results > 0) return;

        setMenuState(values => (
            { ...values, menu: !menuStates["menu"] }
        ));
    };

    function setData(res) {
        dispatch({
            type: "setAccount",
            value: {
                account: {
                    id: res._id || defaultAccount.id,
                    type: res.type || "",
                    username: res.username || "",
                    fullName: res.fullName || defaultAccount.fullName,
                    email: res.email || defaultAccount.email,
                    phone: res.phone || defaultAccount.phone,
                    country: res.country || defaultAccount.country,
                    referral: res.referral || defaultAccount.referral,
                    registeredAddress: res.registeredAddress || defaultAccount.registeredAddress,
                    billingAddress: res.billingAddress || defaultAccount.billingAddress,
                    deliveryAddress: res.deliveryAddress || defaultAccount.deliveryAddress,
                    notifications: res.notifications || defaultAccount.notifications
                }
            }
        });

        dispatch({
            type: "setCart",
            value: res.cart
        });

        dispatch({
            type: "setWishlist",
            value: res.wishlist
        });
    };

    function handleAccountDataFetch() {
        Account.fetchAccountDetails(state.session.auth.actor).then(res => {
            setData(res);
        }).catch(err => {
            var { message } = err.responseJSON;

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.fetchAccountDetails(state.session.auth.actor).then(res => {
                        setData(res);
                    });
                });
            };
        });
    };

    function handleAccountClick(event, redirect) {
        if (state.w3aConnected) {
            if (!state.authenticated) {
                event.preventDefault();
    
                return Paymex.authenticateWallet(state, dispatch);
            };
        };

        if (!state.authenticated) {
            event.preventDefault();

            return handleLogin(null, redirect);
        };

        setMenuState(values => (
            { ...values, menu: false }
        ));

        navigate(redirect);
    };

    function handleConnect(redirect) {
        return Paymex.authenticateWallet(state, dispatch, redirect);
    };

    function handleLogin(session, redirect) {
        const walletSession = session ? (session.auth ? session : state.session) : state.session;

        if (Cookies.get(walletSession.auth.actor + "_has_rt", { domain: ".storex.io", path: "/" })) {
            Account.requestToken(walletSession.auth.actor).then(res => {
                dispatch({
                    type: "setAuth",
                    value: { id: state.session.auth.actor }
                });
            });
        } else {
            Account.verifyWallet(walletSession.auth.actor).then(tokens => {
                Proton.verify(walletSession, tokens.public).then(tx => {
                    Account.requestLogin(walletSession.auth.actor, tokens.public, tokens.private, (tx.serializedTransaction || tx.resolved.serializedTransaction), tx.signatures, tx.signer, tx.resolvedTransaction).then(res => {
                        dispatch({
                            type: "setAuth",
                            value: { id: walletSession.auth.actor }
                        });

                        if (redirect) navigate(redirect);
                    });
                });
            });
        };
    };

    function handleLogout() {
        if (state.w3aConnected) return Paymex.disconnectWallet(state, dispatch);

        if (!state.link || !state.session) return;

        state.link.removeSession("Storex", state.session.auth).then(res => {
            dispatch({
                type: "setAuth",
                value: null
            });

            dispatch({
                type: "setWallet",
                value: null
            });

            setMenuState(values => (
                { ...values, "account": false }
            ));
        });
    };

    function handleDailyDeals() {
        var weeklyDeal = $("div.weekly-deal");

        if (weeklyDeal.length < 1) {
            dispatch({
                type: "viewDeals",
                value: true
            });

            setMenuState(values => (
                { ...values, menu: !menuStates["menu"] }
            ));

            navigate("/");
        } else {
            setMenuState(values => (
                { ...values, menu: !menuStates["menu"] }
            ));
            
            window.scrollTo({ top: weeklyDeal.offset().top - 12, left: 0, behavior: "smooth" });
        };
    };

    function handleRedirect() {
        setMenuState(values => (
            { ...values, menu: false }
        ));
    };

    function handleKeyDown(event) {
        var key = event.key;

        if (key === "Enter") {
            if ($("input[name='search']").is(":focus")) {
                if (inputs.search) {
                    handleRedirect();
                    handleSearch();
                };
            };
        };
    };

    function handleSearch() {
        console.log(inputs.search);
        var encodedTerms = encodeURI(inputs.search || "");

        navigate("/search/" + encodedTerms);
    };

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        setInputs(values => (
            { ...values, [name]: value }
        ));
    };

    return (
        <div>
            <div className="click" onClick={ handleMenu }>
                <div className="icon-wrapper">
                    <i className="fal fa-bars"></i>
                </div>

                <p>Menu</p>
            </div>

            <div className={ "cart-wrapper" + (menuStates["menu"] ? " active" : "") } onClick={ handleMenu }>
                <div className="cart-overlay">
                    <div className="cart-header">
                        <span>Menu</span>
                        <button onClick={ () => setMenuState(values => ( { ...values, menu: !menuStates["menu"] } )) }>{ textStrings["header_cart_close"][state.language] } <i className="fal fa-long-arrow-right"></i></button>
                    </div>

                    <div className="actions">
                        <div onClick={ () => setMenuState(values => ( { ...values, search: !menuStates["search"] } )) }><i className="fal fa-search"></i> Search</div>
                        {
                            (menuStates.search) && (
                                <input type="search" name="search" className="search" value={ inputs.search || "" } onKeyDown={ handleKeyDown } onChange={ handleChange } placeholder={ textStrings["header_search"][state.language] }/>
                            )
                        }
                        <div onClick={ handleDailyDeals }><i className="fal fa-tags"></i> Daily Deals</div>
                        {
                            (state.connected || state.w3aConnected) && (
                                <>
                                    <CartModal mobile={ true }/>
                                    <WishlistModal mobile={ true }/>
                                    <Link to="/notifications"><div onClick={ (e) => handleAccountClick(e, "/notifications") }><i className="fal fa-bell"></i> Notifications ({ state.account?.notifications?.reduce((a, b) => a = a + (!b.markedRead), 0) })</div></Link>
                                    <Link to="/create-listing"><div onClick={ (e) => handleAccountClick(e, "/create-listing") }><i className="fal fa-tag"></i> Create Listing</div></Link>
                                    <Link to="/messages"><div onClick={ (e) => handleAccountClick(e, "/messages") }><i className="fal fa-envelope"></i> Messages</div></Link>
                                    <a href="https://t.me/storexio"><div onClick={ handleRedirect }><i className="fal fa-life-ring"></i> Help Center</div></a>
                                    <div onClick={ () => setMenuState(values => ( { ...values, account: !menuStates["account"] } )) }><i className="fal fa-user"></i> My Account</div>
                                    {
                                        (menuStates.account) && (
                                            <>
                                                <Link to="/account"><div  onClick={ (e) => handleAccountClick(e, "/account") } className="account"><i className="fal fa-map-signs"></i> Dashboard</div></Link>
                                                <Link to="/account/settings"><div  onClick={ (e) => handleAccountClick(e, "/account/settings") } className="account"><i className="fal fa-user-cog"></i> Settings</div></Link>
                                                <Link to="/account/wallet"><div  onClick={ (e) => handleAccountClick(e, "/account/wallet") } className="account"><i className="fal fa-wallet"></i> Wallet</div></Link>
                                                <Link to="/account/addresses"><div  onClick={ (e) => handleAccountClick(e, "/account/addresses") } className="account"><i className="fal fa-map-marker-alt"></i> Addresses</div></Link>
                                                <Link to="/account/orders"><div  onClick={ (e) => handleAccountClick(e, "/account/orders") } className="account"><i className="fal fa-truck"></i> Orders</div></Link>
                                                <Link to="/account/listings"><div  onClick={ (e) => handleAccountClick(e, "/account/listings") } className="account"><i className="fal fa-tags"></i> Active Listings</div></Link>
                                                <Link to="/account/sales"><div  onClick={ (e) => handleAccountClick(e, "/account/sales") } className="account"><i className="fal fa-box-usd"></i> Sales</div></Link>
                                                <Link to={ "/profile/" + state.session.auth.actor }><div  onClick={ handleRedirect } className="account"><i className="fal fa-store"></i> Storefront</div></Link>
                                                <Link to="/account/staking"><div  onClick={ (e) => handleAccountClick(e, "/account/staking") } className="account"><i className="fal fa-piggy-bank"></i> Staking</div></Link>
                                                <Link to="/account/arbitration"><div  onClick={ (e) => handleAccountClick(e, "/account/arbitration") } className="account"><i className="fal fa-gavel"></i> Arbitration</div></Link>
                                                <Link to="/account/vouchers"><div  onClick={ (e) => handleAccountClick(e, "/account/vouchers") } className="account"><i className="fal fa-gift-card"></i> Vouchers</div></Link>
                                            </>
                                        )
                                    }
                                    <div onClick={ handleLogout }><i className="fal fa-sign-out"></i> Sign Out</div>
                                </>
                            )
                        }

                        {
                            (!state.connected && state.w3aConnected != null && !state.w3aConnected) && (
                                <div onClick={ handleConnect }><i className="fal fa-sign-in"></i> Sign In</div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileModal;
