

function CreateAd() {
  return (
    <section className="create-ad">
      <div className="content no-overflow">
        <div className="form-title"><i class="fal fa-ad"></i> Create Ad</div>

        <div className="create-ad-wrapper">
          <div className="thumbnail-wrapper"><img src="https://api.storex.io:444/images/VlBViq9hxRcs4aDEAw2fqRmZRx3554Ht.png" alt="" /></div>
          <h4 className="title">Apple iPhone 14 - Network Unblocked - Brand New</h4>

          <div className="duration-wrapper">
            <span>Duration</span>
            <div className="selection">
              <div className="option">7 days</div>
              <div className="option">14 days</div>
              <div className="option">28 days</div>
            </div>
          </div>

          <div className="price-wrapper">
            <h3 className="price">$15.99</h3>
            <span className="crypto-price"><img src="https://bloks.io/cdn-cgi/image/width=100,height=100/https://raw.githubusercontent.com/eoscafe/eos-airdrops/master/logos/strx.png" alt="STRX" /> 20,552</span>
          </div>

          <button type="submit">Promote</button>
        </div>
      </div>
    </section>
  )
}

export default CreateAd;