/* global BigInt */

import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";
import { useQRCode } from "next-qrcode";

import { useStateValue } from "state";
import { defaultAccount, countryCodes, regionCodesDictionary, countryCodesDictionary, supportedCurrencies, networkMemos } from "constants";
import { isObjectEmpty } from "functions"
import { textStrings } from "localization";

import * as Proton from "assets/javascript/proton";

var supportedCurrenciesCopy = [...supportedCurrencies];

function FarmModal() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ pools: [], inputCurrency: {}, outputCurrency: {}, inputQuantity: 0, outputQuantity: 0, poolShare: 0, selectedTab: "add", removeLiq: 0 });
    const [data, setData] = useState({ balances: { STRX: 0, XPR: 0, BTC: 0, ETH: 0, DOGE: 0, METAL: 0, LOAN: 0, USDC: 0, USDT: 0, XMD: 0, SNIPS: 0, FOOBAR: 0 }, prices: { STRX: 0, XPR: 0, BTC: 0, ETH: 0, DOGE: 0, METAL: 0, LOAN: 0, USDC: 0, USDT: 0, XMD: 0, SNIPS: 0, FOOBAR: 0 }, infinityPosition: {}, stakedPosition: {} });

    useEffect(() => {
        if (!state.inputModal) return;

        if (state.inputModal.action == "farm") {
            setInputs(values => (
                { ...values, inputCurrency: state.inputModal.data.currency }
            ));

            handleDataFetch();

            Proton.isKycVerified(state.session.auth.actor).then(res => {
                setData(values => (
                    { ...values, kyc: res }
                ));
            });
        };
    }, [state.inputModal]);

    useEffect(() => {
        if (!state.closeInputModal) return;

        setInputs({ pools: [], inputCurrency: {}, outputCurrency: {}, inputQuantity: 0, outputQuantity: 0, poolShare: 0, selectedTab: "add" });
        setData({ balances: { STRX: 0, XPR: 0, BTC: 0, ETH: 0, DOGE: 0, METAL: 0, LOAN: 0, USDC: 0, USDT: 0, XMD: 0, SNIPS: 0, FOOBAR: 0 }, prices: { STRX: 0, XPR: 0, BTC: 0, ETH: 0, DOGE: 0, METAL: 0, LOAN: 0, USDC: 0, USDT: 0, XMD: 0, SNIPS: 0, FOOBAR: 0 }, infinityPosition: {}, stakedPosition: {} });
    }, [state.closeInputModal]);

    useEffect(() => {
        if (state.fetchWalletBalances) {
            setTimeout(() => {
                return handleDataFetch();
            }, 3000);
        };
    }, [state.fetchWalletBalances]);

    useEffect(() => {
        if (isObjectEmpty(data.stakedPosition)) return;

        var rewardTimer = setInterval(() => {
            var { poolId, reward, periodFinish, rewardRateE18, rewardPerTokenStored, totalStakingWeight, lastUpdateTime } = data.farmInfo;

            var currentTime = Math.floor(Date.now() / 1000);

            var timeDifference = Math.min(currentTime, periodFinish) - lastUpdateTime;

            var rewardPerToken = parseInt(rewardPerTokenStored) + (timeDifference * parseInt(rewardRateE18)) / parseInt(totalStakingWeight);

            var { stakingWeight, userRewardPerTokenPaid, rewards } = data.stakedPosition;

            var earnedAmount = stakingWeight * (rewardPerToken - userRewardPerTokenPaid);

            var totalRewards = earnedAmount / 1000000000000000000 + rewards;

            var rewardPrecision = reward.quantity?.split(" ")?.[0]?.split(".")?.[1]?.length;

            var [ rewardQuantity, rewardTicker ] = reward.quantity?.split(" ");

            var [ , rewardPrecision ] = rewardQuantity?.split(".");
            
            rewardPrecision = rewardPrecision.length;

            setData(values => (
                { ...values, totalRewards: parseFloat(totalRewards / 10**rewardPrecision).toLocaleString("en-US", { minimumFractionDigits: rewardPrecision }) }
            ));
        }, 1000);

        return () => {
            clearInterval(rewardTimer);
        };
    }, [data.stakedPosition]);

    useEffect(() => {
        if (inputs.inputQuantity <= 0 && inputs.outputQuantity <= 0) return;

        var [ tokenAQuantity, tokenATicker ] = data.tokenA?.quantity?.split(" ");
        var [ tokenBQuantity, tokenBTicker ] = data.tokenB?.quantity?.split(" ");

        var poolValue = (tokenAQuantity * data.prices[tokenATicker]) + (tokenBQuantity * data.prices[tokenBTicker]);
        var liquidityValue = (inputs.inputQuantity * data.prices[inputs.inputCurrency?.ticker]) + (inputs.outputQuantity * data.prices[inputs.outputCurrency?.ticker]);

        setInputs(values => (
            { ...values, poolShare: liquidityValue / (liquidityValue + poolValue) * 100 }
        ));
    }, [inputs.inputQuantity, inputs.outputQuantity]);

    function handleDataFetch() {
        var balances = {};
        var prices = {};
        var oracles = {};

        Proton.fetchOracles().then(res => {
            var oracleArr = res?.rows || [];

            oracleArr.forEach((feed, index) => {
                var feedIndex = feed?.feed_index || 0;
                var feedPrice = feed?.aggregate?.d_double || 0;

                oracles[feedIndex] = feedPrice;

                if (index == oracleArr.length-1) {
                    supportedCurrencies.forEach((currency, index) => {
                        Proton.fetchBalance(state.session.auth.actor, currency).then(res => {
                            const [balanceStr] = res;
                            const [balance, ticker] = (balanceStr || "").split(" ");
            
                            balances[currency.ticker] = balance || "0";

                            prices[currency.ticker] = oracles[currency.feedIndex];

                            if (index == supportedCurrencies.length-1) {
                                Proton.fetchStorexOracles().then(res => {
                                    var oracleArr = res?.rows || [];
                        
                                    oracleArr.forEach((feed, index) => {
                                        const {sym, quantity} = feed;
                                        const [, ticker] = sym.split(",");
                                        const [amount] = quantity.split(" ");
                        
                                        prices[ticker] = amount;

                                        if (index == oracleArr.length-1) {
                                            setData(values => (
                                                { ...values, balances: balances, prices: prices }
                                            ));
                                        };
                                    });
                                }).catch(err => {
                                    console.log(err);
                        
                                    setTimeout(() => {
                                        handleDataFetch();
                                    }, 3000);
                                });
                            };
                        }).catch(err => {
                            console.log(err);
                
                            setTimeout(() => {
                                handleDataFetch();
                            }, 3000);
                        });
                    });
                };
            });
        }).catch(err => {
            console.log(err);

            setTimeout(() => {
                handleDataFetch();
            }, 3000);
        });

        // fetch pool info
        Proton.fetchAlcorFarm(state.inputModal.data.currency.farm).then(r => {
            var { poolId, reward, periodFinish, rewardRateE18, rewardPerTokenStored, totalStakingWeight, lastUpdateTime } = r;

            Proton.fetchAlcorPool(poolId).then(async r => {
                var { tokenA, tokenB, currSlot: { sqrtPriceX64 } } = r;

                var [ tokenAQuantity, tokenATicker ] = tokenA.quantity.split(" ");
                var [ tokenBQuantity, tokenBTicker ] = tokenB.quantity.split(" ");

                setInputs(values => (
                    { ...values, poolId: poolId, outputCurrency: supportedCurrenciesCopy.filter(c => c.ticker == tokenBTicker)[0] }
                ));

                var tokenAPrecision = tokenAQuantity?.split(" ")?.[0]?.split(".")?.[1]?.length;
                var tokenBPrecision = tokenBQuantity?.split(" ")?.[0]?.split(".")?.[1]?.length;

                var priceA = (sqrtPriceX64**2) / (2 ** 128 * 10 ** (tokenBPrecision - tokenAPrecision));
                var priceB = Math.pow(2, 128) / Math.pow(sqrtPriceX64, 2) * 10 ** (tokenBPrecision - tokenAPrecision);

                var currentTime = Math.floor(Date.now() / 1000);

                var timeDifference = Math.min(currentTime, periodFinish) - lastUpdateTime;

                var rewardPerToken = parseInt(rewardPerTokenStored) + (timeDifference * parseInt(rewardRateE18)) / parseInt(totalStakingWeight);

                try {
                    const response = await fetch("https://proton.alcor.exchange/api/v2/swap/pools/" + poolId  + "/positions");
            
                    const r = await response.json();
        
                    var [ infinityPosition ] = r.filter(p => p.owner == state.session.auth.actor && p.tickLower <= -443600 && p.tickUpper >= 443600);
        
                    if (!isObjectEmpty(infinityPosition)) {
                        // check if user has already staked the position in smart contract tables (table: stakes, scope: 102)
                        Proton.fetchAlcorPositionStakes(state.inputModal.data.currency.farm).then(r => {
                            var [ stakedPosition ] = r.filter(s => s.posId == infinityPosition.id);

                            var { stakingWeight, userRewardPerTokenPaid, rewards } = stakedPosition;

                            var earnedAmount = stakingWeight * (rewardPerToken - userRewardPerTokenPaid);

                            var totalRewards = earnedAmount / 1000000000000000000 + rewards;

                            var rewardPrecision = reward.quantity?.split(" ")?.[0]?.split(".")?.[1]?.length;

                            var [ rewardQuantity, rewardTicker ] = reward.quantity?.split(" ");

                            var [ , rewardPrecision ] = rewardQuantity?.split(".");
                            
                            rewardPrecision = rewardPrecision.length;

                            setData(values => (
                                { ...values, balances: state.inputModal.data.balances, prices: state.inputModal.data.prices, tokenA: tokenA, tokenB: tokenB, priceA: priceA, priceB: priceB, infinityPosition: infinityPosition, stakedPosition: stakedPosition, farmInfo: { poolId, reward, periodFinish, rewardRateE18, rewardPerTokenStored, totalStakingWeight, lastUpdateTime }, totalRewards: parseFloat(totalRewards / 10**rewardPrecision).toLocaleString("en-US", { minimumFractionDigits: rewardPrecision }) }
                            ));
                        }).catch(e => {
                            console.log(e);
                        });
                    } else {
                        setData(values => (
                            { ...values, balances: state.inputModal.data.balances, prices: state.inputModal.data.prices, tokenA: tokenA, tokenB: tokenB, priceA: priceA, priceB: priceB, farmInfo: { poolId, reward, periodFinish, rewardRateE18, rewardPerTokenStored, totalStakingWeight, lastUpdateTime } }
                        ));
                    };
                } catch(error) {
                    console.error(error);
                };
            }).catch(error => {
                console.log(error);
            });
        }).catch(error => {
            console.log(error);
        });
    };

    function handleTab(event, tab) {
        setInputs(values => (
            { ...values, selectedTab: tab }
        ));
    };

    function handleSlider(event) {
        $(event.target).parent().css('--value', event.target.value); 
        $(event.target).parent().css('--text-value', JSON.stringify(event.target.value));
        
        setInputs(values => (
            { ...values, removeLiq: event.target.value }
        ));
    };

    function handleChange(event) {
        const name = event.target.name;
        var value = event.target.value;

        if (name.includes("Quantity")) {
            var [, decimals] = value.split(".");

            if (decimals?.length > inputs[name].precision) return;

            value = value.replace(/[^0-9.]/g, "");

            calculateLiquidity(name?.split("Q")?.[0], value);
        };

        setInputs(values => (
            { ...values, [name]: value }
        ));

        $(".form-alert").removeClass("active");
    };

    async function calculateLiquidity(type, quantity) {
        if (type == "input") {
            var inputQuantity_pow = inputs.inputCurrency?.precision;
            var inputQuantity_bn = quantity * 10**inputQuantity_pow;
            var priceA_pow = data.priceA?.toString()?.split(".")?.[1]?.length;
            var priceA_bn = data.priceA * 10**priceA_pow;
            var outputQuantity = inputQuantity_bn*priceA_bn / (10**(inputQuantity_pow + priceA_pow));

            setInputs(values => (
                { ...values, outputQuantity: outputQuantity.toFixed(inputs.outputCurrency?.precision) }
            ));
        } else {
            var outputQuantity_pow = inputs.outputCurrency?.precision;
            var outputQuantity_bn = quantity * 10**outputQuantity_pow;
            var priceB_pow = data.priceB?.toString()?.split(".")?.[1]?.length;
            var priceB_bn = data.priceB * 10**priceB_pow;
            var inputQuantity = outputQuantity_bn*priceB_bn / (10**(outputQuantity_pow + priceB_pow));

            setInputs(values => (
                { ...values, inputQuantity: inputQuantity.toFixed(inputs.inputCurrency?.precision) }
            ));
        };
    };

    function maxInput(type) {
        if (type == "input") {
            setInputs(values => (
                { ...values, inputQuantity: data.balances?.[inputs.inputCurrency?.ticker] }
            ));

            calculateLiquidity("input", data.balances?.[inputs.inputCurrency?.ticker]);
        } else {
            setInputs(values => (
                { ...values, outputQuantity: data.balances?.[inputs.outputCurrency?.ticker] }
            ));

            calculateLiquidity("output", data.balances?.[inputs.outputCurrency?.ticker]);
        };
    };

    function handleAddLiquidity(event) {
        event.preventDefault();

        var button = $("button.farm");
        var buttonHtml = button.html();

        button.html("<i class='fad fa-spinner-third'></i> Adding liquidity").attr("disabled", true);

        var handleSuccess = (txId) => {
            $("div.error-modal").addClass("success").find("div.text").html("Your liquidity has been successfully added</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            dispatch({
                type: "fetchWalletBalances",
                value: true
            });

            button.html(buttonHtml).attr("disabled", false);
        };

        var handleError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error processing your request, please try again");

            button.html(buttonHtml).attr("disabled", false);
        };

        var infinityPosition = data.infinityPosition;

        if (!infinityPosition) {
            Proton.yieldFarm(state, inputs.poolId, inputs.inputCurrency, inputs.inputQuantity, inputs.outputCurrency, inputs.outputQuantity).then(tx => {
                handleSuccess(tx.processed.id);
            }).catch(e => {
                handleError(e.error?.details?.[0]?.message || e);
            });
        } else {
            var stakedPosition = data.stakedPosition;

            Proton.yieldFarm(state, inputs.poolId, inputs.inputCurrency, inputs.inputQuantity, inputs.outputCurrency, inputs.outputQuantity, infinityPosition, stakedPosition).then(tx => {
                handleSuccess(tx.processed.id);
            }).catch(e => {
                handleError(e.error?.details?.[0]?.message || e);
            });
        };
    };

    function handleClaimRewards(event) {
        event.preventDefault();

        var button = $("button.rewards");
        var buttonHtml = button.html();

        button.html("<i class='fad fa-spinner-third'></i> Claiming").attr("disabled", true);

        var handleSuccess = (txId) => {
            $("div.error-modal").addClass("success").find("div.text").html("You have successfully claimed yield farm rewards</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            handleDataFetch();

            button.html(buttonHtml).attr("disabled", false);
        };

        var handleError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error processing your request, please try again");

            button.html(buttonHtml).attr("disabled", false);
        };

        Proton.collectFarmRewards(state, inputs.inputCurrency.farm, data.stakedPosition.posId).then(tx => {
            handleSuccess(tx.processed.id);
        }).catch(e => {
            handleError(e.error?.details?.[0]?.message || e);
        });
    };

    function handleRemoveLiquidity(event) {
        event.preventDefault();

        var button = $("button.remove");
        var buttonHtml = button.html();

        button.html("<i class='fad fa-spinner-third'></i> Removing").attr("disabled", true);

        var handleSuccess = (txId) => {
            $("div.error-modal").addClass("success").find("div.text").html("You have successfully removed liquidity from the farm</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            handleDataFetch();

            button.html(buttonHtml).attr("disabled", false);
        };

        var handleError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error processing your request, please try again");

            button.html(buttonHtml).attr("disabled", false);
        };

        Proton.removeYieldFarm(state, inputs.poolId, Math.ceil(data.infinityPosition.liquidity * (inputs.removeLiq / 100)), inputs.inputCurrency, inputs.outputCurrency).then(tx => {
            handleSuccess(tx.processed.id);
        }).catch(e => {
            handleError(e.error?.details?.[0]?.message || e);
        });
    };

    return (
        <section className="farm">
            <div className="content no-overflow" onClick={ () => (inputs.selectInput || inputs.selectOutput) ? setInputs(values => ({ ...values, selectInput: false, selectOutput: false })) : "" }>
                <div className="form-title"><img src={ inputs.inputCurrency?.logo } alt={ inputs.inputCurrency?.name }></img> Farm { inputs.inputCurrency?.name }</div>

                <div className="swap-wrapper">
                    <div className="tab-wrapper">
                        <button className={"tab " + (inputs.selectedTab == "add" ? "active" : "")} onClick={(e) => handleTab(e, "add")}>Add Liquidity</button>
                        <button className={"tab " + (inputs.selectedTab == "manage" ? "active" : "")} onClick={(e) => handleTab(e, "manage")}>Manage Liquidity</button>
                    </div>

                    {
                        (inputs.selectedTab == "add") && (
                            <>
                                <div className="token base">
                                    <div className="input-wrapper">
                                        <span>Input <span className={ (inputs.inputQuantity * data.prices[inputs.inputCurrency?.ticker] > 0 ? "value active" : "value") }>~{ (inputs.inputQuantity * data.prices[inputs.inputCurrency?.ticker]).toLocaleString("en-US", {style: "currency", currency: "USD"}) }</span></span>
                                        <input type="text" name="inputQuantity" autoComplete="off" placeholder={ (0).toFixed(inputs.inputCurrency?.precision) } value={ inputs.inputQuantity } onChange={ handleChange } required/>
                                    </div>

                                    <div className="token-wrapper">
                                        <span className="click" onClick={ () => maxInput("input") }>Balance: { data.balances?.[inputs.inputCurrency?.ticker] }</span>
                                        <div className="currency">
                                            <img src={ inputs.inputCurrency?.logo } alt={ inputs.inputCurrency?.name } />
                                            <div className="ticker">{ inputs.inputCurrency?.ticker }</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="token quote">
                                    <div className="input-wrapper">
                                        <span>Input <span className={ (inputs.outputQuantity * data.prices[inputs.outputCurrency?.ticker] > 0 ? "value active" : "value") }>~{ (inputs.outputQuantity * data.prices[inputs.outputCurrency?.ticker]).toLocaleString("en-US", {style: "currency", currency: "USD"}) }</span></span>
                                        <input type="text" name="outputQuantity" autoComplete="off" placeholder={ (0).toFixed(inputs.outputCurrency?.precision) } value={ inputs.outputQuantity } onChange={ handleChange } required/>
                                    </div>

                                    <div className="token-wrapper">
                                        <span className="click" onClick={ () => maxInput("output") }>Balance: { data.balances?.[inputs.outputCurrency?.ticker] }</span>
                                        <div className="currency">
                                            <img src={ inputs.outputCurrency?.logo } alt={ inputs.outputCurrency?.name } />
                                            <div className="ticker">{ inputs.outputCurrency?.ticker }</div>
                                        </div>
                                    </div>
                                </div>

                                <i className="fas fa-plus"></i>
                            </>
                        )
                    }

                    {
                        (inputs.selectedTab == "manage") && (
                            <>
                                {
                                    (isObjectEmpty(data.infinityPosition)) && (
                                        <div className="empty">
                                            <i className="fal fa-frown"></i>
                                            <span>No liquidity found</span>
                                        </div>
                                    )
                                }

                                {
                                    (!isObjectEmpty(data.infinityPosition)) && (
                                        <>
                                            <div className="token base">
                                                <div className="input-wrapper">
                                                    <span>Your Position</span>
                                                    <input type="text" name="inputQuantity" autoComplete="off" placeholder={ (0).toFixed(inputs.inputCurrency?.precision) } value={ parseFloat(data.infinityPosition?.amountA?.split(" ")?.[0]).toLocaleString("en-US", { minimumFractionDigits: inputs.inputCurrency?.precision }) } onChange={ handleChange } readonly required/>
                                                </div>

                                                <div className="token-wrapper">
                                                    <span className="click" onClick={ () => maxInput("input") }></span>
                                                    <div className="currency">
                                                        <img src={ inputs.inputCurrency?.logo } alt={ inputs.inputCurrency?.name } />
                                                        <div className="ticker">{ inputs.inputCurrency?.ticker }</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="token quote">
                                                <div className="input-wrapper">
                                                    <span>Your Position</span>
                                                    <input type="text" name="inputQuantity" autoComplete="off" placeholder={ (0).toFixed(inputs.inputCurrency?.precision) } value={ parseFloat(data.infinityPosition?.amountB?.split(" ")?.[0]).toLocaleString("en-US", { minimumFractionDigits: inputs.outputCurrency?.precision }) } onChange={ handleChange } readonly required/>
                                                </div>

                                                <div className="token-wrapper">
                                                    <span className="click" onClick={ () => maxInput("input") }></span>
                                                    <div className="currency">
                                                    <img src={ inputs.outputCurrency?.logo } alt={ inputs.outputCurrency?.name } />
                                                    <div className="ticker">{ inputs.outputCurrency?.ticker }</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="rewards">
                                                <div>
                                                    <div className="title">Total Rewards</div>
                                                    <div className="subtitle">{ data.totalRewards } <img src={ supportedCurrencies[0].logo } alt={ supportedCurrenciesCopy.filter(c => c.ticker == data.rewardsTicker)?.[0]?.logo }/></div>
                                                </div>
                                                <button type="submit" className="rewards" onClick={ handleClaimRewards }>Claim Rewards</button>
                                            </div>

                                            <div className="remove">
                                                <div>
                                                    <div className="title">Remove Liquidity</div>
                                                    <div class="range-slider" style={{'--min': 0, '--max': 100, '--step': 1, '--value': 0, '--text-value': 0}}>
                                                        <input type="range" min="0" max="100" step="1" value={ inputs.removeLiq } onChange={ handleSlider }/>
                                                        <output></output>
                                                        <div class='range-slider__progress'></div>
                                                    </div>
                                                    <div className="outputs">
                                                        <div className="subtitle">{ parseFloat(data.infinityPosition?.amountA?.split(" ")?.[0] * inputs.removeLiq / 100).toLocaleString("en-US", { minimumFractionDigits: inputs.inputCurrency?.precision }) } <img src={ inputs.inputCurrency?.logo } alt={ inputs.inputCurrency?.logo }/></div>
                                                        <div className="subtitle">{ parseFloat(data.infinityPosition?.amountB?.split(" ")?.[0] * inputs.removeLiq / 100).toLocaleString("en-US", { minimumFractionDigits: inputs.outputCurrency?.precision }) } <img src={ inputs.outputCurrency?.logo } alt={ inputs.outputCurrency?.logo }/></div>
                                                    </div>
                                                </div>
                                                <button type="submit" className="remove" onClick={ handleRemoveLiquidity } disabled={ inputs.removeLiq == 0 }>Remove Liquidity</button>
                                            </div>

                                            
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </div>
                
                {
                    (inputs.selectedTab == "add") && (
                        <>
                            <button type="submit" className="farm" onClick={ handleAddLiquidity } disabled={ inputs.inputQuantity <= 0 || inputs.outputQuantity <=0 }>Add Liquidity</button>

                            <div className="info">
                                <div>Current Price <span>{ data.priceA?.toFixed(data.tokenB?.quantity?.split(" ")?.[0]?.split(".")?.[1]?.length) || 0 } { data.tokenB?.quantity?.split(" ")?.[1] }</span></div>
                                <div>Pool Share <span>{ inputs.poolShare?.toFixed(2) }%</span></div>
                            </div>
                        </>
                    )
                }
            </div>
        </section>
    );
};

export default FarmModal;