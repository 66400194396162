import $ from "jquery";
import Cookies from "js-cookie";
import { apiEndpoint, apiDevEndpoint } from "constants";

export function fetchWalletAddresses(user, asset) {
    if (!user) return Promise.reject("user: Undefined");
    if (!asset) return Promise.reject("asset: Undefined");

    return $.ajax({
        type: "GET",
        url: apiDevEndpoint + "/fireblocks/addresses",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user,
            asset: asset
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");

            var { message } = res.responseJSON || { message: res };

            $("div.modal").addClass("error").find("div.text").html(message);
        }
    });
};

export function generateDepositAddress(user, coin, wallet) {
    if (!user) return Promise.reject("user: Undefined");
    if (!coin) return Promise.reject("coin: Undefined");
    if (!wallet) return Promise.reject("wallet: Undefined");

    return $.ajax({
        type: "POST",
        url: apiDevEndpoint + "/fireblocks/wallet/generate",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user,
            coin: coin,
            wallet: wallet
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");

            var { message } = res.responseJSON || { message: res };

            $("div.modal").addClass("error").find("div.text").html(message);
        }
    });
};