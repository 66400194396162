import { React, useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, dummyWeeklyDeal } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Cart from "assets/javascript/cart";
import * as Wishlist from "assets/javascript/wishlist";
import * as Proton from "assets/javascript/proton";

function ListingPage() {
    const { listingId } = useParams();
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ listing: { scrollTop: 0, scrollBottom: 3, variation: 0, option0: [], option1: [], option2: [], buttonClass: "", isCarted: false, isWished: false, sellerKYC: false, deliveryLocation: "", deliveryCost: "", locationsString: "", locationsMore: false, locationsOverflow: false }, checkoutDisabled: true, inactive: false });
    const [data, setData] = useState({ categories: dummyCategories, listing: dummyWeeklyDeal });

    useEffect(() => {
        Listing.fetchListing(state.session.auth.actor, listingId).then(res => {
            res.variations.sort((a, b) => a.id - b.id);
            
            setData(values => (
                {
                    ...values,
                    listing: res,
                    categories: dummyCategories
                }
            ));

            if (res.active && res.broadcast && res.stock > 0) {
                setInputs(values => ({
                    ...values,
                    checkoutDisabled: false
                }));
            } else {
                setInputs(values => ({
                    ...values,
                    checkoutDisabled: true
                }));
            };

            if (!res.active) {
                setInputs(values => ({
                    ...values,
                    inactive: true
                }));
            } else {
                setInputs(values => ({
                    ...values,
                    inactive: false
                }));
            };
        });

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [listingId]);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated || state.authenticated && state.account.deliveryAddress.length < 1) return determineLocation();

        setLocation(state.account?.deliveryAddress?.[0]?.country);
    }, [state.account]);

    useEffect(() => {
        var filterArrCart = state.cart?.filter(item => item._id == data.listing._id && item.variation.id == inputs.listing.variation);

        var isCarted = (filterArrCart?.length > 0) ? true : false;

        var filterArrWish = state.wishlist?.filter(item => item._id == data.listing._id && item.variation.id == inputs.listing.variation);

        var isWished = (filterArrWish?.length > 0) ? true : false;

        setInputs(values => (
            { ...values, listing: { ...values.listing, isCarted: isCarted, isWished: isWished } }
        ));
    }, [data.listing, state.cart, state.wishlist, inputs.listing.variation]);

    useEffect(() => {
        if (Object.keys(data.listing.postage?.international?.available || {}).length > 0) {
            handleShippingLocations(data.listing.postage?.international?.available);
        };

        if (data.listing.postage.location != "") {
            //handleShippingCost();
        };
    }, [inputs.listing.deliveryLocation]);

    useEffect(() => {
        if (!data.listing?.sellerId) return;

        Proton.isKycVerified(data.listing.sellerId).then(res => {
            setInputs(values => (
                { ...values, listing: { ...values.listing, sellerKYC: res } }
            ));
        });
    }, [data.listing?.sellerId]);

    useEffect(() => {
        if (data.listing.options.length > 0) {
            data.listing.options.forEach((option, index) => {
                $("input[name='option" + index + "']").first().attr("checked", true);
                handleOptionChange({ target: { name: "option" + option.id, value: $("input[name='option" + option.id + "']").val() } }, true);
            });
        };
    }, [data.listing.options]);

    function handlePrivilegedRedirect() {
        // if wallet is not connected, handleConnect()

        // if wallet is connected but not authenticated, handle verify tx

        // if wallet is connectd and authenticated redirect to privileged page
    };

    function determineLocation() {
        const f = async () => {
            var req = await fetch("https://ipinfo.io/json?token=d637d120b18ed1");
            var data = await req.json();

            if (data.hasOwnProperty("country")) {
                if (regionDictionary[data.region]) {
                    setLocation(regionDictionary[data.region]);
                } else {
                    setLocation(data.country);
                };
            };
        };

        f();
    };

    function setLocation(countryCode) {
        if (!countryCode) return;

        setInputs(values => ({ ...values, listing: { ...values.listing, deliveryLocation: countryCode } }));
    };

    function handleShippingLocations(shippingArray) {
        var shippingString = "";

        var objectArray = Object.keys(shippingArray);

        objectArray.forEach((key, index) => {
            shippingString += countryCodesDictionary[key];

            if (index != objectArray.length-1) shippingString += ", ";
        });

        setInputs(values => ({ ...values, listing: { ...values.listing, locationsString: shippingString } }));
    };

    // function handleShippingCost() {
    //     var [locationCountry] = data.listing.postage.location.split("-");
    //     var [destinationCountry] = inputs.listing.deliveryLocation.split("-");

    //     if (locationCountry == "" || destinationCountry == "") return;

    //     if (locationCountry == destinationCountry) {
    //         if (data.listing.postage.domestic?.available?.[inputs.listing.deliveryLocation]) {
    //             var priceRaw = data.listing.postage.domestic?.available?.[inputs.listing.deliveryLocation];
    //             var priceString = (parseFloat(priceRaw) == 0) ? "FREE" : parseFloat(priceRaw).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 });

    //             setInputs(values => ({ ...values, listing: { ...values.listing, deliveryCost: priceString } }));
    //         };
    //     } else {
    //         if (data.listing.postage.international?.available?.[inputs.listing.deliveryLocation]) {
    //             var priceRaw = data.listing.postage?.international.available?.[inputs.listing.deliveryLocation];
    //             var priceString = (parseFloat(priceRaw) == 0) ? "FREE" : parseFloat(priceRaw).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 });

    //             setInputs(values => ({ ...values, listing: { ...values.listing, deliveryCost: priceString } }));
    //         } else {
    //             setInputs(values => ({ ...values, listing: { ...values.listing, deliveryCost: "unavailable" } }));
    //         };
    //     };
    // };

    function handleShippingCost(postage) {
        var [locationCountry] = postage.location.split("-");
        var [destinationCountry] = inputs.listing.deliveryLocation.split("-");

        if (locationCountry == "" || destinationCountry == "") return;

        if (locationCountry == destinationCountry) {
            if (postage.domestic?.available?.[destinationCountry]) {
                var priceRaw = postage.domestic?.available?.[destinationCountry];
                var priceString = (parseFloat(priceRaw) == 0) ? "FREE" : parseFloat(priceRaw).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 });

                return priceString;
            };
        } else {
            if (postage.international?.available?.[destinationCountry]) {
                var priceRaw = postage.international?.available?.[destinationCountry];
                var priceString = (parseFloat(priceRaw) == 0) ? "FREE" : parseFloat(priceRaw).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 });

                return priceString;
            } else {
                return "unavailable"
            };
        };
    };

    function handleShippingRecalc(event) {
        const name = event.target.name;
        var value = event.target.value;

        setInputs(values => (
            { ...values, listing: { ...inputs.listing, deliveryLocation: value } }
        ));
    };

    function handleThumbnail(event, src) {
        if ($(event.target).parent().attr("id").split("-")[1] == inputs.listing.scrollTop) handleScroll(false);
        if ($(event.target).parent().attr("id").split("-")[1] == inputs.listing.scrollBottom) handleScroll(true);

        $(event.target).parent().addClass("active").siblings().removeClass("active");

        setData(values => (
            { ...values, listing: { ...values.listing, thumbnail: src } }
        ));
    };

    function handleScroll(increment) {
        if (increment) {
            if (inputs.listing.scrollBottom > data.listing.images.length - 2) return;

            setInputs(values => (
                { ...values, listing: { ...values.listing, scrollTop: inputs.listing.scrollTop + 1, scrollBottom: inputs.listing.scrollBottom + 1 } }
            ));

            document.getElementById("previews").scrollBy({ top: 0, left: 114.25, behavior: "smooth" });
        } else {
            if (inputs.listing.scrollTop < 1) return;

            setInputs(values => (
                { ...values, listing: { ...values.listing, scrollTop: inputs.listing.scrollTop - 1, scrollBottom: inputs.listing.scrollBottom - 1 } }
            ));

            document.getElementById("previews").scrollBy({ top: 0, left: -114.25, behavior: "smooth" });
        };
    };

    function handleRating(rating) {
        var classObject = {};
        var remainder = (rating % 1).toFixed(1);

        for (var i = 0; i < Math.floor(rating); i++) {
            classObject[i] = "fas fa-star fill";
        };

        if (remainder > 0 && remainder < 0.3) {
            classObject[Math.floor(rating)] = "fas fa-star";
        } else if (remainder >= 0.3 && remainder < 0.8) {
            classObject[Math.floor(rating)] = "fad fa-star-half";
        } else if (remainder >= 0.8) {
            classObject[Math.floor(rating)] = "fas fa-star";
        };

        for (var i = 0; i < 5; i++) {
            if (!classObject[i]) classObject[i] = "fas fa-star";
        };

        return (
            <div>
                <i className={ classObject[0] }></i>
                <i className={ classObject[1] }></i>
                <i className={ classObject[2] }></i>
                <i className={ classObject[3] }></i>
                <i className={ classObject[4] }></i>
            </div>
        );
    };

    function handleOptionChange(event, manual) {
        // initialise vars
        var selectedOptions = {};
        var selectedVariation = { id: 0, price: data.listing.price, stock: 0 };

        // construct object with currently selected options
        data.listing.options.forEach((option, optionIndex) => {
            selectedOptions["option" + optionIndex] = $("input[name='option" + optionIndex + "']:checked").val();
        });

        // iterate variations and check if the option values match the currently selected option values
        data.listing.variations.forEach((variation, variationIndex) => {
            var variationMatch = true;

            Object.keys(selectedOptions).map((optionName) => {
                if (!variationMatch) return;

                if (selectedOptions[optionName] != variation[optionName]) variationMatch = false;
            });

            if (variationMatch) {
                selectedVariation.id = variation.id;
                selectedVariation.price = variation.price;
                selectedVariation.stock = variation.stock;
            };
        });

        // set the selected variation id, price, stock
        setInputs(values => (
            { ...values, listing: { ...values.listing, variation: selectedVariation.id } }
        ));

        setData(values => (
            { ...values, listing: { ...values.listing, price: selectedVariation.price, stock: selectedVariation.stock } }
        ));

        // check which option combinations are available with the currently selected options
        
        // make non-selected options disabled, if available, re-enable
        //$(".options input").not(":checked").attr("disabled", true);

        // iterate selected options and construct array of which ones need disabling
        // Object.keys(selectedOptions).map((optionName) => {
        //     // iterate variations if optionName val == variation optionName val
        //     data.listing.variations.forEach((variation, variationIndex) => {
        //         if (!variation[optionName]) return;

        //         if (variation[optionName] == selectedOptions[optionName]) {
        //             Object.keys(variation).map(name => {
        //                 if (name.includes("option") && name != optionName) {
        //                     $("input[name='" + name + "'][value='" + variation[name] + "']").attr("disabled", false);
        //                 }
        //             });
        //         };
        //     });
        // });
    };

    // function handleOptionChange(event, manual) {
    //     const name = event.target.name;
    //     const value = event.target.value;

    //     var optionValues = {};

    //     data.listing.options.forEach(option => {
    //         var optionId = parseInt(name.split("option")[1]);

    //         if (option.id == optionId) {
    //             optionValues["option" + option.id] = value;
    //         } else {
    //             optionValues["option" + option.id] = $("input[name='option" + option.id + "']:checked").val();
    //         };

    //         var optionNameElems = $("input[name='" + name + "']");

    //         if (optionNameElems.length < 2) return;

    //         if ("option" + option.id != name) {
    //             var optionElems = $("input[name='" + "option" + option.id + "']");

    //             var disabledArr = [];

    //             optionElems.map(index => {
    //                 var optionElem = optionElems[index];

    //                 var variationExists = false;

    //                 data.listing.variations.forEach(variation => {
    //                     if (variation[name] == value) {
    //                         if (variation["option" + option.id] == optionElem.value && variation.stock > 0) return variationExists = true;
    //                     };
    //                 });

    //                 if (variationExists) {
    //                     disabledArr.push(false);
    //                 } else {
    //                     disabledArr.push(true);
    //                 };
    //             });

    //             setInputs(values => {
    //                 if (manual) {
    //                     var currentArr = values.listing["option" + option.id];

    //                     currentArr.forEach((currentValue, index) => {
    //                         if (currentValue) {
    //                             disabledArr[index] = true;
    //                         };
    //                     });
    //                 };

    //                 return { ...values, listing: { ...values.listing, ["option" + option.id]: disabledArr } };
    //             });
    //         };
    //     });

    //     var variationPrice = data.listing.price;
    //     var variationStock = 0;

    //     data.listing.variations.forEach(variation => {
    //         var match = true;

    //         Object.keys(optionValues).forEach(key => {
    //             if (optionValues[key] != variation[key]) return match = false;
    //         });

    //         if (match) {
    //             variationPrice = variation.price;
    //             variationStock = variation.stock;

    //             setInputs(values => (
    //                 { ...values, listing: { ...values.listing, variation: variation.id } }
    //             ));

    //             return;
    //         };
    //     });

    //     setData(values => (
    //         { ...values, listing: { ...data.listing, price: variationPrice, stock: variationStock } }
    //     ));
    // };

    function handleOptionReset(fullReset) {
        if (data.listing.options.length > 0) {
            data.listing.options.forEach((option, optionIndex) => {
                var optionInputs = $("input[name='option" + optionIndex + "']");

                for (let i = 0; i < optionInputs.length; i++) {
                    $(optionInputs[i]).prop("checked", false).prop("disabled", false);

                    if (i == 0 && !fullReset) $(optionInputs[i]).prop("checked", true);
                };
            });

            if (!fullReset) handleOptionChange({ target: { name: "option0", value: $("input[name='option0']").first().val() } });
        };
    };

    // function handleOptionReset() {
    //     if (data.listing.options.length > 0) {
    //         for (let i = 0; i < data.listing.options.length; i++) {
    //             var elems = $("input[name='option" + i + "']");

    //             for (let e = 0; e < elems.length; e++) {
    //                 $(elems[e]).prop("checked", false).prop("disabled", false);
    //             };

    //             $("input[name='option" + i + "']").first().prop("checked", true);

    //             handleOptionChange({ target: { name: "option" + i, value: $("input[name='option" + i + "']").val() } }, true);
    //         };
    //     };
    // };

    function handlePurchase(event) {
        if (inputs.checkoutDisabled) return;

        if (inputs.listing.isCarted) {
            navigate("/checkout");
        } else {
            handleCart(true, true);
        };
    };

    function handleCart(addItem, purchase) {
        if (inputs.checkoutDisabled) return;
        
        if (inputs.listing.isCarted && !addItem) {
            var showSuccess = (res) => {
                dispatch({
                    type: "setCart",
                    value: res
                });

                //$("div.error-modal").addClass("success").find("div.text").html("Removed listing from shopping cart");
            };

            var showError = () => {
                $("div.error-modal").addClass("error").find("div.text").html("There was an error removing listing from shopping cart");
            };

            Cart.removeListing(state.session.auth.actor, data.listing._id, inputs.listing.variation, 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                console.log(error);
                var { message } = error.responseJSON;

                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Cart.removeListing(state.session.auth.actor, data.listing._id, inputs.listing.variation, 1).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        } else {
            var showSuccess = (res) => {
                dispatch({
                    type: "setCart",
                    value: res.cart
                });
                
                if (purchase) return navigate("/checkout");

                if (res.exceedStock) {
                    //$("div.error-modal").addClass("warning").find("div.text").html("Added listing to shopping cart with remaining stock");
                } else {
                    //$("div.error-modal").addClass("success").find("div.text").html("Added listing to shopping cart");
                };
            };

            var showError = (e) => {
                $("div.error-modal").addClass("error").find("div.text").html(e || "There was an error adding listing to shopping cart");
            };

            Cart.addListing(state.session.auth.actor, data.listing._id, inputs.listing.variation, 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                console.log(error);
                var { message } = error.responseJSON;

                if (message == "no auth") {
                    dispatch({
                        type: "promptLogin",
                        value: "/"
                    });

                    return;
                };

                if (message == "cart_ac_vari_no_stock") {
                    showError("This variation is not in stock, please choose a different one");

                    return;
                };

                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Cart.addListing(state.session.auth.actor, data.listing._id, inputs.listing.variation, 1).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        };
    };

    function handleWishlist(addItem) {
        if (inputs.listing.isWished && !addItem) {
            var showSuccess = (res) => {
                dispatch({
                    type: "setWishlist",
                    value: res
                });

                //$("div.error-modal").addClass("success").find("div.text").html("Removed listing from shopping cart");
            };

            var showError = () => {
                $("div.error-modal").addClass("error").find("div.text").html("There was an error removing listing from wishlist");
            };

            Wishlist.removeListing(state.session.auth.actor, data.listing._id, inputs.listing.variation, 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                console.log(error);
                var { message } = error.responseJSON;

                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Wishlist.removeListing(state.session.auth.actor, data.listing._id, inputs.listing.variation, 1).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        } else {
            var showSuccess = (res) => {
                dispatch({
                    type: "setWishlist",
                    value: res.wishlist
                });
            };

            var showError = () => {
                $("div.error-modal").addClass("error").find("div.text").html("There was an error adding listing to wishlist");
            };

            Wishlist.addListing(state.session.auth.actor, data.listing._id, inputs.listing.variation, 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                console.log(error);
                var { message } = error.responseJSON;

                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Wishlist.addListing(state.session.auth.actor, data.listing._id, inputs.listing.variation, 1).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        };
    };

    // function handleWishlist() {
    //     const hasClass = $("button.wishlist").hasClass("wished");

    //     if (hasClass) {
    //         setInputs(values => (
    //             { ...values, listing: { ...inputs.listing, isWished: false } }
    //         ));

    //         // send req to unwatch
    //     } else {
    //         setInputs(values => (
    //             { ...values, listing: { ...inputs.listing, isWished: true } }
    //         ));

    //         // send req to watch
    //     };
    // };

    function handleTabClick(event) {
        var id = $(event.target).attr("id");

        $(event.target).addClass("active").siblings().removeClass("active");

        $(".tab-content").find("." + id).css("display", "block").siblings().css("display", "none");

        // display tab info
    };

    return (
        <section className="listing">
            <div className="container">
                <div className="breadcrumb-wrapper">
                    <div className="breadcrumb">
                        <Link to="/"><p>Home</p></Link>
                        <span>></span>
                        <Link to={ data.categories[data.listing.category]["url"] }><p>{ textStrings[data.listing.category][state.language] }</p></Link>
                        <span>></span>
                        <Link to={ data.categories[data.listing.category]["url"] + data.categories[data.listing.category]["subcategories"][data.listing.subcategory]["url"] }><p>{ textStrings[data.listing.subcategory][state.language] }</p></Link>
                    </div>

                    <div className="report click">Report Listing</div>
                </div>

                <div className="listing-wrapper">
                    <div className="content-wrapper">
                        <div className="top-wrapper">
                            <div className="mobile-title wb600">{ data.listing.title }</div>

                            <div className="thumbnail-wrapper">
                                <div className="thumbnail">
                                    {
                                        (data.listing.thumbnail.search("youtube") == -1) && (
                                            <img src={ data.listing.thumbnail } alt={ data.listing.title }/>
                                        )
                                    }

                                    {
                                        (data.listing.thumbnail.search("youtube") != -1) && (
                                            <iframe src={ "https://youtube.com/embed/" + (data.listing.thumbnail.split("v=")[1]) } frameBorder="0"></iframe>
                                        )
                                    }
                                </div>

                                <div className="previews-wrapper">
                                    <div className="previews" id="previews">
                                        {
                                            data.listing.images.sort((a, b) => a.id - b.id).map((image, index) => (
                                                <div className={ "image click " + (index == 0 ? "active" : "") } key={ index } id={ "image-" + index } onClick={ event => handleThumbnail(event, image.src) }>
                                                    {
                                                        (image.src.search("youtube") == -1) && (
                                                            <img src={ image.src } alt=""/>
                                                        )
                                                    }

                                                    {
                                                        (image.src.search("youtube") != -1) && (
                                                            <>
                                                                <i className="fad fa-play-circle"></i>
                                                                <img src={ "https://i.ytimg.com/vi/" + (image.src.split("v=")[1]) + "/hqdefault.jpg" } alt=""/>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="summary-wrapper">
                                {
                                    (inputs.inactive && data.listing.suspended) && (
                                        <div className="suspended"><i className="fal fa-ban"></i> This listing has been suspended</div>
                                    )
                                }

                                {
                                    (inputs.inactive && !data.listing.suspended) && (
                                        <div className="inactive"><i className="fal fa-ban"></i> This listing is no longer active</div>
                                    )
                                }

                                <div className="title n600">{ data.listing.title }</div>

                                <div className="price">
                                    { parseFloat(data.listing.price || 0).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }

                                    {
                                        (handleShippingCost(data.listing.postage) != "unavailable") && (
                                            <div className="postage">+ <span className={ handleShippingCost(data.listing.postage)?.toLowerCase() }>{ handleShippingCost(data.listing.postage) }</span> shipping</div>
                                        )
                                    }

                                    {
                                        // (inputs.listing.deliveryCost != "unavailable") && (
                                        //     <div className="postage">+ <span className={ inputs.listing.deliveryCost.toLowerCase() }>{ inputs.listing.deliveryCost }</span> shipping</div>
                                        // )
                                    }
                                </div>

                                <div className="reviews" title={ data.listing.feedback?.reduce((a, b) => a + b.rating, 0) / data.listing.feedback?.length || 0 }>{ handleRating(data.listing.feedback?.reduce((a, b) => a + b.rating, 0) / data.listing.feedback?.length) } <span>{ "(" + (data.listing.feedback?.length || 0) + " ratings)" }</span></div>

                                <div className="stock">
                                    { data.listing.variations.filter(variation => variation.id == inputs.listing.variation)[0]?.stock > 0 ? <div className="in-stock"><i className="fas fa-check"></i><span>In Stock</span></div> : <div className="out-stock"><i className="fas fa-times"></i><span>Out of Stock</span></div> }
                                </div>

                                <div className="condition">Condition: { data.listing.condition }</div>

                                <div className="options">
                                    {
                                        (data.listing.options.length < 1) && (
                                            <span className="no-options">No options available</span>
                                        )
                                    }

                                    {
                                        (data.listing.options.length > 0) && (
                                            data.listing.options.map((option, index) => (
                                                <div className="attribute" key={ option.id }>
                                                    <span>{ option.name == "custom" ? option.custom : option.name }:</span>
                                                    <div className="radio-wrapper">
                                                        {
                                                            data.listing.variations.map(variation => {
                                                                return variation["option" + index]
                                                            }).filter((value, index, self) => {
                                                                return self.indexOf(value?.trim()) === index
                                                            }).map((variation, index2) => (
                                                                <div key={ index2 }>
                                                                    <input type="radio" id={ variation + "-" + index } name={ "option" + index } value={ variation } disabled={ inputs.listing["option" + index][index2] } onChange={ handleOptionChange }/>
                                                                    <label htmlFor={ variation + "-" + index } className="click">{ variation }</label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        )
                                    }

                                    <div className="reset click" onClick={ handleOptionReset }><i className="fal fa-redo"></i> Reset Options</div>
                                </div>

                                <div className="checkout">
                                    <div className="row">
                                        <button className={ inputs.checkoutDisabled ? "purchase disabled" : "purchase" } onClick={ handlePurchase }><i className="fas fa-tag"></i> Purchase</button>
                                    </div>

                                    <div className="row">
                                        {
                                            inputs.listing.isCarted ? <button className="cart remove-cart"><i className="far fa-minus-circle" onClick={ () => handleCart(false) }></i> { state.cart.filter(item => item._id == data.listing._id && item.variation.id == inputs.listing.variation)[0]?.quantity + " in cart" } <i className="far fa-plus-circle" onClick={ () => handleCart(true) }></i></button> : <button className={ inputs.checkoutDisabled ? "cart add-cart disabled" : "cart add-cart" } onClick={ handleCart }><i className="fas fa-shopping-cart"></i> Add To Cart</button>
                                        }

                                        <button className={ "wishlist" + (inputs.listing.isWished ? " wished" : "") } onClick={ () => inputs.listing.isWished ? handleWishlist(false) : handleWishlist(true) }><i className={ "fas" + (inputs.listing.isWished ? " fa-heart-broken" : " fa-heart") }></i> { inputs.listing.isWished ? "Unwishlist" : "Wishlist" }</button>
                                    </div>
                                </div>

                                <div className="currencies">
                                    <span>Payment Methods:</span>
                                    {
                                        data.listing.currencies.map((currency, index) => {
                                            if (index < 4) {
                                                return (
                                                    <img key={ index } src={ currency.logo } alt={ currency.name } title={ currency.name }></img>
                                                );
                                            } else {
                                                if (index < 5 && data.listing.currencies.length < 6) {
                                                    return (
                                                        <img key={ index } src={ currency.logo } alt={ currency.name } title={ currency.name }></img>
                                                    );
                                                } else {
                                                    return (
                                                        <div key={ index } className="overflow click">
                                                            <div className="number">+{ index + 1 - 4 }</div>
                                                        </div>
                                                    );
                                                };
                                            };
                                        })
                                    }
                                </div>

                                {
                                    (state.kyc && data.listing.currencies.filter(currency => currency.name == "STRX").length > 0) && (
                                        <div className="cashback">
                                            <span>Cashback Eligibility:</span>

                                            <div className="amount">${ (parseFloat(data.listing.price) / 100 * 3).toFixed(2) }</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        <div className="bottom-wrapper">
                            <div className="description-tabs">
                                <button className="tablink active" id="description" onClick={ handleTabClick }>Description</button>
                                <button className="tablink" id="shipping" onClick={ handleTabClick }>Shipping</button>
                                <button className="tablink" id="reviews" onClick={ handleTabClick }>Reviews ({ data.listing.feedback?.length || 0 })</button>
                            </div>

                            <div className="tab-content">
                                <div className="description w3-animate-top">
                                    <p dangerouslySetInnerHTML={{ __html: decodeURIComponent((data.listing.description || "Description")?.replaceAll("<script>", "").replaceAll("</script>", "").replaceAll("<script/>", "").replaceAll("%0A", "</br>")) }}/>
                                </div>

                                <div className="shipping w3-animate-top" style={{ display: "none" }}>
                                    <form action="">
                                        <div className="content">
                                            <div className="item-location">{ countryCodesDictionary[data.listing.location] }</div>
                                            <i className={ "fas" + (data.listing.location == inputs.listing.deliveryLocation ? " fa-truck" : " fa-plane") }></i>
                                            <div className="input-group">
                                                <select name="buyer-location" id="buyer-location" value={ inputs.listing?.deliveryLocation || "" } onChange={ handleShippingRecalc }>
                                                    {
                                                        countryCodes.map((country, index) => (
                                                            <option key={ index } value={ country.countryCode } disabled={ (data.listing?.postage?.domestic?.available?.[country.countryCode] != null || data.listing?.postage?.international?.available?.[country.countryCode] != null) ? false : true }>{ country.name }</option>
                                                        ))
                                                    }
                                                </select>
                                                <i className="fal fa-chevron-down"></i>
                                            </div>
                                        </div>
                                    </form>

                                    {
                                        (handleShippingCost(data.listing.postage) == "unavailable") && (
                                            <div className="postage unavailable">Shipping not available to { countryCodesDictionary[inputs.listing.deliveryLocation] }</div>
                                        )
                                    }

                                    {
                                        (handleShippingCost(data.listing.postage) != "unavailable") && (
                                            <div className={ "postage" + (handleShippingCost(data.listing.postage) == "FREE" ? " free" : "") }>Shipping Price: <span>{ handleShippingCost(data.listing.postage) }</span></div>
                                        )
                                    }
                                </div>

                                <div className="reviews w3-animate-top" style={{ display: "none" }}>
                                    {
                                        (data.listing?.feedback?.length < 1) && (
                                            <p>There are no reviews for this listing</p>
                                        )
                                    }

                                    {
                                        (data.listing?.feedback?.length > 0) && (
                                            data.listing?.feedback?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())?.map((review, index) => (
                                                <div className="review" key={ index }>
                                                    <span>
                                                        <div className="date">{ new Date(review.createdAt).toLocaleDateString() }</div>
                                                        <div className="verified">Verified Purchase</div>
                                                    </span>
                                                    { handleRating(data.listing.feedback?.reduce((a, b) => a + b.rating, 0) / data.listing.feedback?.length) }
                                                    <p>{ review.comment }</p>
                                                </div>
                                            ))
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sidebar-wrapper">
                        <div className="mobile-grid">
                            <div className="listing-features">
                                {
                                    (data.listing.escrow) && (
                                        <div className="feature">
                                            <i className="fal fa-shield-check"></i>
                                            <p>Protected By Escrow <i className="fal fa-info-circle" title="Your payment will be protected by the Storex Escrow until you conclude the transaction."></i></p>
                                        </div>
                                    )
                                }

                                {
                                    (!data.listing.escrow) && (
                                        <div className="feature">
                                            <i className="fal fa-shield warning"></i>
                                            <p>Not Protected By Escrow <i className="fal fa-info-circle" title="Your payment will be sent directly to the seller and will not be protected by the Storex Escrow."></i></p>
                                        </div>
                                    )
                                }

                                {
                                    (inputs.listing.sellerKYC) && (
                                        <div className="feature">
                                            <i className="fal fa-badge-check"></i>
                                            <p>Verified Seller <i className="fal fa-info-circle" title="This seller has verified their personal information on the Proton blockchain."></i></p>
                                        </div>
                                    )
                                }

                                {
                                    (!inputs.listing.sellerKYC) && (
                                        <div className="feature">
                                            <i className="fal fa-badge warning"></i>
                                            <p>Seller Not Verified <i className="fal fa-info-circle" title="This seller has not verified their personal information on the Proton blockchain."></i></p>
                                        </div>
                                    )
                                }

                                {
                                    (data.listing?.seller?.moreListings?.reduce((a, b) => a + b.stats?.sales, 0) > 3) && (
                                        <div className="feature">
                                            <i className="fal fa-star-shooting"></i>
                                            <p>Top Seller</p>
                                        </div>
                                    )
                                }

                                {
                                    (data.listing?.sellerId == "strxdev") && (
                                        <div className="feature">
                                            <i className="fal fa-star"></i>
                                            <p>Featured Item</p>
                                        </div>
                                    )
                                }

                                {
                                    (data.listing?.stats?.sales > 3) && (
                                        <div className="feature">
                                            <i className="fal fa-fire-alt"></i>
                                            <p>Popular Item</p>
                                        </div>
                                    )
                                }

                                <div className="feature">
                                    <i className="fal fa-shipping-fast"></i>
                                    <p>Express Shipping Available</p>
                                </div>
                            </div>

                            <div className="item-location">
                                <i className="fal fa-globe-americas"></i>
                                <p>Sent from { countryCodesDictionary[data.listing.location] }</p>
                            </div>

                            <div className="seller-profile">
                                <Link to={ "/profile/" + data.listing?.seller?._id }>
                                    <div className="avatar">
                                        <img src={ data.listing?.seller?.avatar || "/assets/images/default_avatar.webp" } alt={ data.listing?.seller?.displayName }/>
                                    </div>
                                </Link>

                                <div className="wrapper">
                                    <div className="text">
                                        <Link to={ "/profile/" + data.listing?.seller?._id }><div className="username">{ data.listing?.seller?.displayName || data.listing?.seller?._id } <i className={ "fas fa-badge-check" + (inputs.listing.sellerKYC ? " active" : "") } title="KYC Verified"></i></div></Link>
                                        {/*<div className="joined">{ "Joined " + new Date(data.listing?.seller?.createdAt).toLocaleDateString() }</div>*/}
                                    </div>

                                    <Link to={ "/messages/" + data.listing?.seller?._id }><i className="fal fa-comment"></i></Link>
                                </div>
                            </div>
                        </div>

                        <div className="other-items">
                            <div className="heading">More Items <i className="fal fa-store"></i></div>

                            <div className="listings-wrapper">
                                {
                                    data.listing?.seller?.moreListings?.filter(l => l.active).slice(0, 4).map((listing, index) => (
                                        <div className="listing" key={ index }>
                                            <Link to={ "/listing/" + listing._id }>
                                                <div className="thumbnail-wrapper">
                                                    <img src={ listing.thumbnail } alt={ listing.title }/>
                                                </div>
                                            </Link>

                                            <div className="details-wrapper">
                                                <Link to={ "/listing/" + listing._id }><div className="listing-title">{ listing.title }</div></Link>
                                                <div className="listing-reviews" title={ listing.feedback?.reduce((a, b) => a + b.rating, 0) / listing.feedback?.length || 0 }>{ handleRating(listing.feedback?.reduce((a, b) => a + b.rating, 0) / listing.feedback?.length) } <span>{ "(" + (listing.feedback?.length || 0) + " ratings)" }</span></div>
                                                <div className="listing-price">{ parseFloat(listing.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ListingPage;
